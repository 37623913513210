import React, {Children, cloneElement, isValidElement, useCallback, useRef, useState} from "react";
import './TabView.scss';
import {i18n} from "../../I18n/I18n";

export default function TabView(props) {
    const {children} = props;

    const _children = children.filter
        ? children.filter(item => item != null && item !== false)
        : children;

    // Kontrollera att varje barn har en key
    for (let i = 0; i < _children.length; i++) {
        const child = _children[i];
        if (!child.key) {
            throw new Error(`Child '${child.props.header}${child.props.headerI18n}' saknar key.`);
        }
    }

    // State för att hålla aktuella kvantiteter
    const [quantityMap, setQuantityMap] = useState(new Map());

    const tabViewRef = useRef({
        updateQuantity: (index, quantity) => updateQuantityMap(index, quantity)
    })

    // Hantera uppdateringar till quantityMap
    const updateQuantityMap = useCallback((index, quantity) => {
        setQuantityMap(prevMap => {
            const newMap = new Map(prevMap);
            newMap.set(index, quantity);
            return newMap;
        });
    }, []);

    const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0);

    // Uppdaterade barnkomponenter med props
    const childrenWithProps = Children.map(_children, (child, index) => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                index: index,
                active: activeIndex === index,
                quantity: quantityMap.get(index) || index + 10, // Standardvärde om quantity saknas
                tabViewRef
            });
        }
        return child;
    });

    return (
        <div className="TabView">
            <header>
                {Children.map(_children, (item, index) => {
                    if (!item) return null;
                    return (
                        <TabHeaderItem
                            key={item.key}
                            item={item}
                            index={index}
                            activeIndex={activeIndex}
                            onActiveIndexChange={setActiveIndex}
                            quantity={quantityMap.get(index)}
                        />
                    );
                })}
            </header>
            {childrenWithProps}
        </div>
    );
}

function TabHeaderItem(props) {
    const {item, index, activeIndex, onActiveIndexChange, quantity} = props;

    const className = index === activeIndex ? 'active' : '';

    return (
        <div
            key={item.key}
            className={className}
            onClick={() => onActiveIndexChange(index)}
        >
            {item.props.header}
            {item.props.headerI18n && i18n(item.props.headerI18n)}
            {quantity !== undefined && ` (${quantity})`}
        </div>
    );
}
