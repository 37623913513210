import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import './XlnzFileUpload.scss';
import Icon from "../../enums/Icon";
import {MainButton} from "../Buttons/Button";

const XlnzFileUpload = forwardRef(({
                                       name = 'files[]',
                                       auto = false,
                                       customUpload = false,
                                       uploadHandler = () => {
                                       },
                                       multiple = false,
                                       accept = '',
                                       maxFileSize = 40000000,
                                       headerTemplate = null,
                                       headerClassName = '',
                                       emptyTemplate = null,
                                       chooseLabel = 'Choose file...',
                                   }, ref) => {
    const [files, setFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        clear() {
            setFiles([]);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }));

    const onFilesSelected = (selectedFiles) => {
        const validFiles = Array.from(selectedFiles).filter(file => file.size <= maxFileSize);
        setFiles(validFiles);

        if (customUpload && uploadHandler) {
            uploadHandler({files: validFiles});
        } else if (auto) {
            uploadHandler({files: validFiles});
        }
    };

    const handleChooseClick = () => {
        fileInputRef.current.click();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);

        const selectedFiles = event.dataTransfer.files;
        if (selectedFiles.length) {
            onFilesSelected(selectedFiles);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const renderHeader = () => {
        if (headerTemplate) {
            return headerTemplate(files);
        }
        return (
            <div className={`xlnz-file-upload-header ${headerClassName}`}>
                <span>{files.length ? `Selected files: ${files.length}` : 'No files selected'}</span>
            </div>
        );
    };

    const renderEmptyTemplate = () => {
        if (emptyTemplate) {
            return emptyTemplate();
        }
        return <span className="xlnz-file-upload-empty">No files chosen</span>;
    };

    return (
        <div
            className={`xlnz-file-upload ${dragActive ? 'drag-active' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div className='xlnz-file-upload__header'>
                <MainButton faicon={Icon.ADD} label={chooseLabel} onClick={handleChooseClick}/>
                {renderHeader()}
            </div>
            <div className="xlnz-file-upload-content">
                {/*<button type="button" onClick={handleChooseClick} className="xlnz-file-upload-choose">*/}
                {/*    {chooseLabel}*/}
                {/*</button>*/}
                <input
                    ref={fileInputRef}
                    type="file"
                    name={name}
                    multiple={multiple}
                    accept={accept}
                    style={{display: 'none'}}
                    onChange={(e) => onFilesSelected(e.target.files)}
                />
            </div>
            <div className="xlnz-file-upload-files">
                {files.length === 0 ? renderEmptyTemplate() : (
                    <ul>
                        {files.map((file, idx) => (
                            <li key={idx}>{file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
});

export default XlnzFileUpload;
