import {faImage} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import PdfViewer from "../../../components/Appendix/PdfViewer";
import {DownloadButton, RemoveButton} from "../../../components/Buttons/Button";
import Checkbox from "../../../components/EditForm/Checkbox";
import Label from "../../../components/EditForm/Label";
import FileUpload from "../../../components/FileUpload/FileUpload";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzSideBar from "../../../components/XlnzSideBar/XlnzSideBar";
import useWindowSize from "../../../hooks/useWindowSize";
import NetUtils from "../../../utils/NetUtils";
import SupplierinvoiceService from "../SupplierinvoiceService";
import './SupplierinvoiceImageSidebar.scss'

const SupplierinvoiceImageSidebar = props => {
    const {
        save,
        form,
    } = props
    const [imageUrl, setImageUrl] = useState()
    const [enableZoom, setEnableZoom] = useState()

    const [width] = useWindowSize()
    const isUsingSidebar = width < 1800

    const [
        id,
        fileSize
    ] = form?.getValues(['id', 'fileSize'])

    useEffect(() => {
        if (fileSize > 0) {
            SupplierinvoiceService.getImageUrl(id).then(_resp => {
                setImageUrl(_resp.url)
            })
        }
    }, [fileSize, id])


    const sidebarContent = <>
        <div className='button-row'>
            <div className='left-buttons'>
                {
                    !isUsingSidebar &&
                    <div className='enable-zoom'>
                        <Label valueI18n='enable_zoom'/>
                        <Checkbox checked={enableZoom} onChange={(e) => setEnableZoom(!enableZoom)}/>
                    </div>
                }

            </div>
            <div className='divider'></div>
            <div className='right-buttons'>
                {
                    fileSize > 0 &&
                    <DownloadButton labelI18n='download'
                                    onClick={async () => {
                                        showLoading()
                                        NetUtils.downloadFile(imageUrl).then(() => hideLoading())
                                    }}
                    />
                }
                {
                    fileSize > 0 &&
                    <RemoveButton onClick={e => {
                        confirmYes(() => {
                            const data = form.getValues()

                            showLoading()
                            delete data.fileSize
                            save(data).then(_model => {
                                form.reset(_model);
                                hideLoading()
                            })
                        })
                    }}/>
                }
            </div>
        </div>

        <div className='SupplierinvoiceImageSidebar-container'>
            {
                fileSize > 0 &&
                <div className='pdf-viewer-container'>
                    <PdfViewer url={imageUrl} enableZoom={!isUsingSidebar && enableZoom}/>
                </div>
            }
            {
                !fileSize &&
                <FileUpload multiple={false} save={save} {...form}/>
            }
        </div>
    </>

    return <div className='SupplierinvoiceImageSidebar'>
        {
            !isUsingSidebar &&
            sidebarContent
        }

        {
            isUsingSidebar &&
            <XlnzSideBar faicon={faImage}>
                <div style={{width: '1000px', zIndex: '999999'}}>
                    {sidebarContent}
                </div>
            </XlnzSideBar>
        }
    </div>
}
export default SupplierinvoiceImageSidebar