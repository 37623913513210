import React, {useEffect, useState} from 'react';
import {CURRENCY} from "../../App";
import {AddButton} from "../../components/Buttons/Button";
import DatePicker from "../../components/Date/DatePicker";
import Label from "../../components/EditForm/Label";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match, matchCostcenter} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useDialog from "../../hooks/useDialog";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {distinct, findById, sortByField} from "../../utils/ArrayUtil";
import {todayDate, todayYear} from "../../utils/DateUtils";
import {projectNumberComparator} from "../../utils/SortUtils";
import ProjectService from "./ProjectService";
import ProjectStatus from "./ProjectStatus";
import useProjectContextMenuItems from "./useProjectContextMenuItems";

const ProjectInvoiceOverviewGrid = () => {
    const roles = useRoles()
    const preload = usePreload()
    const dialog = useDialog()

    const contextMenu = useProjectContextMenuItems({dialog: dialog})

    const costcenterList = preload.get(PreloadType.COSTCENTER_LIST).filter(item => item.active)
    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)

    const filter = useFilter('ProjectGrid', [
        {name: 'status', defaultValue: ProjectStatus.ONGOING.id},
        {name: 'date', defaultValue: todayDate()},
        {name: 'year', defaultValue: todayYear()},
        {name: 'costcenter', defaultValue: undefined, values: costcenterList},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const projectLeaderList = sortByField(distinct(list.map(item => item.projectLeader)).map(id => findById(employeeList, id)), 'name')
    const filteredList = list?.filter(item => matchCostcenter(filter, item) && match(filter, item, 'projectLeader'))

    useEffect(() => {
        showLoading()
        const params = {
            status: filter.status,
            startDate: filter.date,
            endDate: filter.date,
            year: filter.year,
            search: filter.search
        }
        ProjectService.listInvoiceOverview(params).then(_list => {
            setList(_list);
            hideLoading()
        });
    }, [filter.status, filter.date, filter.year, filter.search])

    const uberColumns = [
        {
            header: i18n('costplus_work').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.4)'},
            startColumnField: 'costplusToInvoice',
            colSpan: 3
        },
        {
            header: i18n('fixed_price').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.2)'},
            startColumnField: 'tenderUninvoiced',
            colSpan: 2
        },
        {
            header: i18n('rent').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.4)'},
            startColumnField: 'rentUninvoiced',
            colSpan: 2
        },
        {
            header: i18n('supplier_invoice').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.2)'},
            startColumnField: 'supplierinvoicesUninvoiced',
            colSpan: 2
        },
        {
            header: i18n('total').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.6)'},
            startColumnField: 'totalUninvoiced',
            colSpan: 3
        },
    ]


    const COL_WIDTH = 100
    const columns = [
        {
            field: 'label',
            headerI18n: 'project',
            minWidth: 200,
            sortFunction: (p1, p2) => projectNumberComparator(p1.number, p2.number)
        },
        {field: 'customerName', minWidth: 150, headerI18n: 'customer', body: GridTemplates.onelineString, mobile: true},

        {
            field: 'costplusToInvoice',
            hasUberHeader: true,
            header: i18n('uninvoiced') + ' (h)',
            body: GridTemplates.hours, footer: FooterAggregate.SUM_HOURS,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'costplusUninvoiced',
            hasUberHeader: true,
            header: i18n('uninvoiced') + ' (' + CURRENCY.suffix + ')',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },
        {
            field: 'costplusInvoiced',
            hasUberHeader: true,
            header: i18n('invoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },

        {
            field: 'tenderUninvoiced',
            hasUberHeader: true,
            header: i18n('uninvoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },
        {
            field: 'tenderInvoiced',
            hasUberHeader: true,
            header: i18n('invoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },

        {
            field: 'rentUninvoiced',
            hasUberHeader: true,
            header: i18n('uninvoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },
        {
            field: 'rentInvoiced',
            hasUberHeader: true,
            header: i18n('invoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },

        {
            field: 'supplierinvoicesUninvoiced',
            hasUberHeader: true,
            header: i18n('uninvoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },
        {
            field: 'supplierinvoicesInvoiced',
            hasUberHeader: true,
            header: i18n('invoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right'
        },

        {
            field: 'totalUninvoiced',
            hasUberHeader: true,
            header: i18n('uninvoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
            mobileWithHeader: true
        },
        {
            field: 'otherInvoiced',
            hasUberHeader: true,
            header: i18n('inv_other'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
            mobileWithHeader: true
        },
        {
            field: 'totalInvoiced',
            hasUberHeader: true,
            header: i18n('invoiced'),
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
            mobileWithHeader: true
        },
    ];

    const leftFilters = [
        roles.hasRole(Role.TENDER_WRITE) ? <div>
            <Label value='&nbsp;'/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <XlnzDropdown
                value={filter.status}
                options={ProjectStatus.values()}
                onChange={e => filter.update('status', e.value)}
                style={{width: '150px'}}/>
        </div>
    ]

    if (filter.status === ProjectStatus.COMPLETED.id || filter.status === ProjectStatus.CANCELLED.id) {
        leftFilters.push(<div>
            <Label valueI18n='year'/>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='date'/>
        <DatePicker value={filter.date} onChange={date => filter.update('date', date)}/>
    </div>)

    if (costcenterList?.length > 0) {
        leftFilters.push(<div>
            <Label valueI18n='costcenter'/>
            <XlnzDropdown
                value={filter.costcenter}
                options={costcenterList}
                optionLabel='label'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('costcenter', e.value)}
                style={{width: '250px'}}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='project_leader'/>
        <XlnzDropdown
            value={filter.projectLeader}
            options={projectLeaderList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('projectLeader', e.value)}
            style={{width: '200px'}}/>
    </div>)

    return <>
        <Grid id='ProjectInvoiceOverviewGrid'
              forceDesktop={true}
              labelI18n='invoice_overview'
              icon={Icon.PROJECT}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              uberColumns={uberColumns}
              columns={columns}
              value={filteredList}
              contextMenu={contextMenu}
              expandableField='parent'
        />
        {dialog.render()}
    </>
}

export default ProjectInvoiceOverviewGrid;
