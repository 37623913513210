import Company from "../../enums/Company";
import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import Borealis from "./customercustom/enter/borealis/Borealis";
import EnterMonthlyReport from "./customercustom/enter/monthlyReport/EnterMonthlyReport";
import EnterNovoReport from "./customercustom/enter/novo/EnterNovoReport";
import St1 from "./customercustom/enter/st1/St1";
import CustomerHoursAndInvoiced from "./customerHoursAndInvoiced/CustomerHoursAndInvoiced";
import EmployeeStatistics from "./employeeStatistics/EmployeeStatistics";
import MonthlyTurnoverPerAccount from "./monthlyTurnoverPerAccount/MonthlyTurnoverPerAccount";
import OngoingProjectsReport from "./ongoingProjectsReport.js/OngoingProjectsReport";
import ProjectHoursAndInvoiced from "./projectHoursAndInvoiced/ProjectHoursAndInvoiced";
import PurchasePlan from "./purchasePlan/PurchasingPlan";
import Recoinvites from "./recoinvites/Recoinvites";
import TenderFollowups from "./tenderFollowups/TenderFollowups";

const ReportsIndex = {
    routeProps: {
        path: '/reports',
        exact: true,
        items: [
            {
                nameKey: 'project_hours_and_invoiced',
                path: '/reports/project-hoursandinvoiced',
                exact: true,
                element: <ProjectHoursAndInvoiced/>
            },
            {
                nameKey: 'ongoing_projects',
                path: '/reports/ongoing-projects',
                exact: true,
                element: <OngoingProjectsReport/>
            },
            {
                nameKey: 'employee_statistics',
                path: '/reports/employee-statistics',
                exact: true,
                element: <EmployeeStatistics/>
            },
            {
                nameKey: 'tender_followup',
                path: '/reports/tender-followup',
                exact: true,
                element: <TenderFollowups/>
            },
            {
                nameKey: 'reco_invites',
                path: '/reports/reco-invites',
                exact: true,
                element: <Recoinvites/>,
                isAccessable: (context) => {
                    return context.currentCompany.propList.find(p => p.name === Companyprop.RECO_VENUE_ID.name) !== undefined
                }
            },
            {
                nameKey: 'customers_hours_and_invoiced',
                path: '/reports/customers-hoursandinvoiced',
                exact: true,
                element: <CustomerHoursAndInvoiced/>
            },
            {
                nameKey: 'monthly_turnover_per_account',
                path: '/reports/monthly-turnover-per-account',
                exact: true,
                element: <MonthlyTurnoverPerAccount/>
            },


            {
                name: 'Enter - Månadsrapport',
                path: '/reports/enter/monthly-report',
                exact: true,
                element: <EnterMonthlyReport/>,
                isAccessable: (context) => context.currentCompany.id === Company.ENTER
            },
            {
                name: 'Borealis',
                path: '/reports/enter/borealis',
                exact: true,
                element: <Borealis/>,
                isAccessable: (context) => context.currentCompany.id === Company.ENTER_UDDEVALLA_BOREALIS
            },
            {
                name: 'Enter - Novo',
                path: '/reports/enter/novo',
                exact: true,
                element: <EnterNovoReport/>,
                isAccessable: (context) => context.currentCompany.id === Company.ENTER_UDDEVALLA_NOVO
            },
            {
                name: 'Enter - St1',
                path: '/reports/enter/st1',
                exact: true,
                element: <St1/>,
                isAccessable: (context) => context.currentCompany.id === Company.ENTER_UDDEVALLA_ST1
            },


            {
                nameKey: 'purchase_plan',
                path: '/reports/purchase-plan',
                exact: true,
                element: <PurchasePlan/>,
                isAccessable: (context) => context.currentCompany.id === Company.MOBILBYGG || context.currentCompany.id === Company.REELS_PROJEKT
            },
        ]
    },
    nameKey: 'reports',
    icon: Icon.REPORT,
    showHeadline: true,
    sortByLabel: true,
    roles: [Role.REPORT_READ]
}
export default ReportsIndex;