import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../../../components/Buttons/Button";
import DatePicker from "../../../../../components/Date/DatePicker";
import Label from "../../../../../components/EditForm/Label";
import {useFilter} from "../../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../../components/Grid/getFooterGroup";
import Grid from "../../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import Icon from "../../../../../enums/Icon";
import {matchSearch} from "../../../../../functions/match";
import {GridTemplates} from "../../../../../GridTemplates";
import {getFirstDateOfMonth, todayDate} from "../../../../../utils/DateUtils";
import NetUtils from "../../../../../utils/NetUtils";

const EnterNovoReport = () => {
    const filter = useFilter('EnterMonthlyReport', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [model, setModel] = useState()
    const filteredList = model?.itemList.filter(item => matchSearch(filter, item)) || []

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/enter/novo', {
            startDate: filter.startDate,
            endDate: filter.endDate
        }).then(_model => {

            for (const item of _model.itemList) {
                for (const debittypeName of _model.debittypeNameList) {
                    console.log("ITEM", item, debittypeName)

                    item[debittypeName] = item.debitHours
                        .filter(row => row.debittypeName === debittypeName)
                        .reduce((sum, row) => sum + row.hours, 0);

                    // break
                }
            }

            setModel(_model)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [
        {field: 'date', header: 'Datum', width: 100},
        {
            field: 'projectNumber',
            headerI18n: 'project',
            width: 80
        },
        {field: 'employeeName', headerI18n: 'employee'},
    ]


    model?.debittypeNameList.forEach(debittypeName => {
        columns.push({
            field: debittypeName,
            header: debittypeName,
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: 80,
            align: 'right'
        })
    })

    columns.push({
        field: 'hours',
        header: 'Totalt',
        body: GridTemplates.hours,
        footer: FooterAggregate.SUM_HOURS,
        width: 80,
        align: 'right'
    },)

    columns.push(...[
        {
            field: 'workDone',
            headerI18n: 'work_done'
        },
        {
            field: 'articleName',
            headerI18n: 'article'
        },
        {
            field: 'articleQuantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            align: 'right',
            width: 80,
        },
        {
            field: 'unit',
            headerI18n: 'unit',
            body: GridTemplates.unit,
            width: 120,
        },
    ])

    // columns.push({field: 'padding', header: '',})

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <Grid
        id='Enter - Novo'
        name='Enter - Novo'
        forceDesktop={true}
        label='Enter - Novo'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default EnterNovoReport;