import React, {useState} from 'react';
import DateStepper from "../../../components/Date/DateStepper";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchCostcenter, matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {addDays, getFirstDateOfWeek, isHoliday, isWeekend, isWithinInterval} from "../../../utils/DateUtils";
import DaylockService from "../DaylockService";
import getDayBody from "./getDayBody";
import getDayEditor from "./getDayEditor";
import getDayHeader from "./getDayHeader";
import getWeekUberHeaders from "./getWeekUberHeaders";
import WeekplanningService from "./WeekplanningService";
import './WeekplanningView.scss'

export const WEEKPLANNING_NUM_OF_DAYS = 21

const WeekplanningView = () => {
    const roles = useRoles()
    const filter = useFilter('WeekplanningView', [
        {name: 'date', defaultValue: getFirstDateOfWeek()},
        {name: 'costcenter', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [rowList, setRowList] = useState()
    const [daylockList, setDaylockList] = useState()
    const disabled = !roles.hasRole(Role.TIMEREG_WRITE);

    const preload = usePreload()
    const costcenterList = preload.getCostcenterList().filter(item => item.active)

    const filteredList = rowList?.filter(c => matchCostcenter(filter, c) && matchSearch(filter, c))

    const onCellEditComplete = async e => {
        const rowData = e.rowData
        const date = e.field

        const oldValue = rowData.dayList.find(r => r.date === date)?.quantity || 0
        const newValue = e.newValue || 0

        if (newValue !== oldValue) {
            showLoading()
            const _model = {
                projectId: rowData.project,
                date: date,
                quantity: newValue
            }
            await WeekplanningService.update(_model)

            const _rowList = [...rowList]
            const weekplanningDay = rowData.dayList.find(r => r.date === date)
            weekplanningDay.quantity = newValue
            setRowList(_rowList)
            hideLoading()
        }
    }

    const loadData = async date => {
        showLoading()
        const [
            _rowList,
            _daylockList,
        ] = await Promise.all([
            WeekplanningService.findByDate(date),
            DaylockService.findByDateInterval(date, addDays(date, WEEKPLANNING_NUM_OF_DAYS - 1))
        ])
        _rowList.forEach(wp => {
            wp.dayList.forEach(item => wp[item.date] = item.quantity)
        })
        setRowList(_rowList)
        setDaylockList(_daylockList)
        hideLoading()
    }
    useMountEffect(() => loadData(filter.date))


    const columns = [{field: 'projectName', header: i18n('project'), minWidth: 150}]

    if (rowList) {
        for (let i = 0; i < WEEKPLANNING_NUM_OF_DAYS; i++) {
            const date = addDays(filter.date, i)
            const dayLocked = isDayLocked(date)

            const classNames = ['dayColumn']
            if (isHoliday(date, true)) classNames.push('holiday')
            if (isWeekend(date)) classNames.push('weekend')

            columns.push({
                field: date,
                hasUberHeader: true,
                header: getDayHeader(date, i, dayLocked),
                footer: FooterAggregate.SUM_INTEGER,
                body: item => getDayBody(item, i, isProjectInactive(date, item)),
                editor: e => getDayEditor(e, e.rowData, i, isProjectInactive(date, e.rowData), dayLocked),
                onCellEditComplete: onCellEditComplete,
                align: 'center',
                className: classNames.join(' '),
                width: 43,
            })
        }
    }

    columns.push({
        field: 'projectNote',
        headerI18n: 'note',
        body: GridTemplates.stringLimit100,
        minWidth: 100
    })

    const isProjectInactive = (mDate, item) => !isWithinInterval(mDate, item.startDate, item.endDate)

    function isDayLocked(date) {
        return daylockList?.find(dl => dl.date === date) !== undefined
    }

    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DateStepper value={filter.date} onChange={date => {
                filter.update('date', date)
                loadData(date)
            }}/>
        </div>
    ]

    if (costcenterList?.length > 0) {
        leftFilters.push(<div>
            <label className="label">{i18n('costcenter')}</label>
            <XlnzDropdown
                value={filter.costcenter}
                options={costcenterList}
                optionLabel='label'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('costcenter', e.value)}
                style={{width: '250px'}}/>
        </div>)
    }

    if (!rowList || !daylockList) return <Spinner/>
    return <div className='WeekplanningView'>
        <Grid
            forceDesktop={true}
            disabled={disabled}
            labelI18n='week_planning'
            icon={Icon.TIMEREG}
            leftFilters={leftFilters}
            filter={filter}
            scrollable={true}
            uberColumns={getWeekUberHeaders(filter.date)}
            columns={columns}
            value={filteredList}
            sortable={false}
            onRowSelect={() => {
            }}
        >
        </Grid>
    </div>
}

export default WeekplanningView;
