import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../../../components/Buttons/Button";
import DatePicker from "../../../../../components/Date/DatePicker";
import Label from "../../../../../components/EditForm/Label";
import {useFilter} from "../../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../../components/Grid/getFooterGroup";
import Grid from "../../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import Icon from "../../../../../enums/Icon";
import {matchSearch} from "../../../../../functions/match";
import {GridTemplates} from "../../../../../GridTemplates";
import {getFirstDateOfMonth, todayDate} from "../../../../../utils/DateUtils";
import NetUtils from "../../../../../utils/NetUtils";

const EnterMonthlyReport = () => {
    const filter = useFilter('EnterMonthlyReport', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [model, setModel] = useState()
    const filteredList = model?.itemList.filter(item => matchSearch(filter, item)) || []

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/enter/monthly-report', {
            startDate: filter.startDate,
            endDate: filter.endDate
        }).then(_model => {

            for (const item of _model.itemList) {
                for (const debittypeName of _model.debittypeNameList) {
                    // console.log("ITEM", item, debittypeName)

                    item[debittypeName] = item.debitHours
                        .filter(row => row.debittypeName === debittypeName)
                        .reduce((sum, row) => sum + row.hours, 0);

                    // break
                }
            }

            setModel(_model)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [{field: 'companyName', header: 'Avdelning', body: GridTemplates.onelineString, minWidth: 160}]

    model?.debittypeNameList.forEach(debittypeName => {
        columns.push({
            field: debittypeName,
            header: debittypeName,
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: 100,
            align: 'right'
        })
    })

    columns.push({
        field: 'hours',
        header: 'Totalt',
        body: GridTemplates.hours,
        footer: FooterAggregate.SUM_HOURS,
        width: 80,
        align: 'right'
    },)

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <Label/>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <Grid
        id='Enter-Månadsrapport'
        name='Enter - Månadsrapport'
        forceDesktop={true}
        label='Enter - Månadsrapport'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default EnterMonthlyReport;