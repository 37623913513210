/**
 * XLNZ COMMON REACT - Do not edit elsewhere
 */
import {CURRENCY} from "../App";
import Unit from "../enums/Unit";

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const round = (value, numOfDecimals = 0) => {
    const smallValue = value > 0 ? Number.EPSILON : -Number.EPSILON;
    return Math.round((value + smallValue) * (10 ** numOfDecimals)) / (10 ** numOfDecimals);
}

export const formatHours = (value, keepZero = false) => {
    let res = value
    if (res && res !== 0) {
        return res.toLocaleString(undefined) + ' h';
    }
    if (keepZero) {
        return '0 h'
    }
    return ''
}

export const formatNumber = (value, numOfDecimals, keepZero) => {
    value = Number(value);
    if (!numOfDecimals) {
        numOfDecimals = 0;
    }
    if (keepZero && value === 0) return '0';

    if (isNaN(value) || value === 0) return '';

    value = round(value, numOfDecimals)
    if (value === 0) value = Math.abs(value)

    if (numOfDecimals === -1) {
        return value.toLocaleString(undefined);
    } else {
        return value.toLocaleString(undefined, {
            minimumFractionDigits: numOfDecimals,
            maximumFractionDigits: numOfDecimals
        });
    }
}

export const formatCurrency = (value, numOfDecimals, keepZero = false) => {
    let formattedValue = formatNumber(value, numOfDecimals, keepZero);
    if (keepZero && formattedValue.length === 0) return '0 ' + CURRENCY.suffix
    return formattedValue.length > 0 ? formattedValue + ' ' + CURRENCY.suffix : '';
}

export const formatTwoDecimalsIfNeeded = (value, keepZero = false) => {
    return formatNumber(value, Math.min(2, NumberUtils.countDecimals(value)), keepZero);
}

export const formatCurrencyTwoDecimalsIfNeeded = (value, keepZero = false) => {
    const numDecimals = NumberUtils.countDecimals(value) > 0 ? 2 : 0;
    const result = formatNumber(value, Math.min(2, numDecimals), keepZero);
    return result + (result.length > 0 ? ' ' + CURRENCY.suffix : '')
}

export const formatWeight = (value, numOfDecimals = 2, keepZero = false) => {
    let formattedValue = formatNumber(value, numOfDecimals, keepZero);
    return formattedValue.length > 0 ? formattedValue + ' kg' : '';
}

export const formatPercent = (value, numOfDecimals) => isNaN(value) || value === 0 ? '' : formatNumber(value * 100, numOfDecimals) + " %"

//Eg. Discount of 75% -> 0.75
export const getDiscountFactor = discount => (1 - discount / 100);

export class NumberUtils {

    static formatCurrency(value, numOfDecimals, keepZero = false) {
        let formattedValue = this.format(value, numOfDecimals)
        if (keepZero && formattedValue.length === 0) return '0 ' + CURRENCY.suffix
        return formattedValue.length > 0 ? formattedValue + ' ' + CURRENCY.suffix : '';
    }

    static formatCurrencyPerHour(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' ' + CURRENCY.suffix + '/h' : '';
    }

    static formatWeight(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' kg' : '';
    }

    static formatSquareMeter(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' m\u00B2' : '';
    }

    static formatCubicMeter(value, numOfDecimals) {
        let formattedValue = this.format(value, numOfDecimals);
        return formattedValue.length > 0 ? formattedValue + ' ' + Unit.CUBIC_METER.symbol : '';
    }

    static format(value, numOfDecimals, keepZero) {
        return formatNumber(value, numOfDecimals, keepZero)
    }

    static formatTwoDecimalsIfNeeded = value => {
        return NumberUtils.format(value, Math.min(2, NumberUtils.countDecimals(value)));
    }

    static formatTwoDecimals = (value) => this.format(value, 2);

    static hoursNoPostfix(value) {
        let res = value
        if (res !== 0) {
            res /= 100
            return res.toLocaleString(undefined);
        }
        return ''
    }

    static hours(value) {
        return formatHours(value)
    }


    static formatDuration(value) {
        let result = ''
        if (value > 0) {

            let hours = parseInt(value);
            let minutes = Math.round((value - hours) * 60);

            result = hours + ":" + (minutes < 10 ? '0' : '') + minutes;

            // if (hours > 0) {
            //     result += hours + "h ";
            // }
            // result += minutes + ' min';
        }
        return result
    }

    static formatPercent(value, numOfDecimals) {
        if (isNaN(value) || value === 0) {
            return '';
        } else {
            return this.format(value * 100, numOfDecimals) + " %";
        }
    }

    static round(value, numOfDecimals) {
        const smallValue = value > 0 ? Number.EPSILON : -Number.EPSILON;
        return Math.round((value + smallValue) * (10 ** numOfDecimals)) / (10 ** numOfDecimals);
    }

    static sumByField = (list, field) => list.reduce((sum, a) => sum += !isNaN(a[field]) ? a[field] : 0, 0)

    static isNumeric = n => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    static parse = (n, defaultValue) => {
        const result = Number(n);
        return NumberUtils.isNumeric(result) ? result : defaultValue
    }

    static countDecimals = value => {
        if (!value) return 0
        if (Math.floor(value) === value) return 0;
        if (value.toString().indexOf('.') === -1) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    static formatBytes = (value, numOfDecimals = 2) => {
        if (value === 0) return '0 Bytes';

        const k = 1024;
        const dm = numOfDecimals < 0 ? 0 : numOfDecimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(value) / Math.log(k));

        return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

