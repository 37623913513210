import download from "downloadjs";
import {errorAlert} from "../components/Overlay/Alert";
import {getXlnzGridFooterValue} from "../components/XlnzGrid/XlnzGridFooter";
import {GridTemplates} from "../GridTemplates";
import {i18n} from "../I18n/I18n";
import {findByField} from "./ArrayUtil";
import {formatTimeFileSafe} from "./DateUtils";


export function formatUrlParams(params) {
    if (!params) return ''
    const result = '?' + Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .map(key => key + '=' + encodeURIComponent(params[key])).join('&');

    return result !== '?' ? result : ''
}

const doGet = async (url, params) => {
    url += formatUrlParams(params)
    // if (params) {
    //     url += '?' + Object.keys(params)
    //         .filter(key => params[key] !== undefined && params[key] !== null)
    //         .map(key => key + '=' + params[key]).join('&');
    // }
    return await
        fetch(url).then(async _resp => {
            return _resp.ok ? _resp.json() : handleError(_resp);
        }, () => {
            console.error("Unable to load url:", url)
        })
}

const doPost = async (url, model) => await fetch(url, {
    method: 'POST', headers: {"Content-Type": "application/json; charset=utf-8"},
    body: model ? JSON.stringify(model) : undefined
}).then(resp => resp.ok ? resp.json() : handleError(resp));

const doPostWithFiles = async (url, model, files, filesName = 'file') => {
    var formData = new FormData();
    formData.append('model', new Blob([JSON.stringify(model)], {type: "application/json"}));
    if (files) {
        files.map(file => formData.append(filesName, file, file.name));
    }
    return await fetch(url, {
        method: 'POST',
        body: formData,
    }).then(resp => resp.ok ? resp.json() : handleError(resp));
}

// const doPostWithFiles = async (url, model, files) => {
//     var formData = new FormData();
//     formData.append('model', new Blob([JSON.stringify(model)], {type: "application/json"}));
//     if (files) {
//         files.map(file => formData.append('file', file, file.name));
//     }
//     return await fetch(url, {
//         method: 'POST',
//         body: formData,
//     }).then(resp => resp.ok ? resp.json() : handleError(resp));
// }


const doDelete = async (url, model) => await fetch(url, {
    method: 'DELETE', headers: {"Content-Type": "application/json; charset=utf-8"},
    body: JSON.stringify(model)
}).then(resp => resp.ok ? true : handleError(resp));

// [
//     {
//         "header": "LÖPANDE ARBETE",
//         "style": {
//             "backgroundColor": "rgba(184, 207, 105, 0.4)"
//         },
//         "startColumnField": "costplusToInvoice",
//         "colSpan": 3
//     },
//     {
//         "header": "FAST PRIS",
//         "style": {
//             "backgroundColor": "rgba(184, 207, 105, 0.2)"
//         },
//         "startColumnField": "tenderUninvoiced",
//         "colSpan": 2
//     },
//     {
//         "header": "HYRA",
//         "style": {
//             "backgroundColor": "rgba(184, 207, 105, 0.4)"
//         },
//         "startColumnField": "rentUninvoiced",
//         "colSpan": 2
//     },
//     {
//         "header": "LEVERANTÖRSFAKTURA",
//         "style": {
//             "backgroundColor": "rgba(184, 207, 105, 0.2)"
//         },
//         "startColumnField": "supplierinvoicesUninvoiced",
//         "colSpan": 2
//     },
//     {
//         "header": "TOTALT",
//         "style": {
//             "backgroundColor": "rgba(184, 207, 105, 0.6)"
//         },
//         "startColumnField": "totalUninvoiced",
//         "colSpan": 3
//     }
// ]


const exportToExcel = (filename, uberColumns, columns, values) => {
    const timestampPostfix = formatTimeFileSafe()

    function getValue(col) {
        if (col?.excelHeader) return col?.excelHeader
        if (col?.excelHeaderI18n) return i18n(col?.excelHeaderI18n)
        if (col?.headerI18n) return i18n(col?.headerI18n)
        return col?.label || col?.header
    }

    let data = []

    if (uberColumns) {
        const uberData = []
        for (const col of columns) {
            const uberCol = findByField(uberColumns, 'startColumnField', col.field)
            uberData.push(uberCol ? getValue(uberCol) : '')
        }
        data.push(uberData)
    }

    data.push(columns.map(getValue))
    data = data.concat(values.map(item => columns.map(col => {
        if (col.formula) {
            return '#FORMULA:' + col.formula
        } else {
            if (['pdf', 'remove'].indexOf(col.field) !== -1) {
                return undefined
            } else if (col.excelBody) {
                return col.excelBody(item, col)
            } else if (col.body) {
                if (col.body === GridTemplates.multilineString
                    || col.body === GridTemplates.onelineString
                    || col.body === GridTemplates.hours
                    || col.body === GridTemplates.numberTwoDecimals
                    || col.body === GridTemplates.currencyTwoDecimals
                    || col.body === GridTemplates.percentTimes100
                    || col.body === GridTemplates.weight
                    || col.body === GridTemplates.currency
                    || col.body === GridTemplates.integer
                    || col.body === GridTemplates.cubicMeter
                ) {
                    return item[col.field]
                } else {
                    return col.body(item, col)
                }
            } else {
                return item[col.field]
            }
        }
    })))

    const footer = columns.map((col, index) => getXlnzGridFooterValue(columns, values, col, index, false))
    data.push(footer)

    const _filename = filename.replaceAll(' ', '_')

    const model = {
        numOfHeaderRows: uberColumns ? 2 : 1,
        numOfFooterRows: 1,
        data: data
    }

    fetch('/api/excel', {
        method: 'POST', headers: {"Content-Type": "application/json; charset=utf-8"},
        body: JSON.stringify(model)
    }).then(res => res.blob()).then(blob => {
        download(blob, _filename + '_' + timestampPostfix + '.xlsx')
    })
}


const getFilename = response => {
    const contentDisposition = response.headers.get("content-disposition")
    let result = contentDisposition.substring('attachment; filename="'.length, contentDisposition.length - 1)
    result = decodeURIComponent(result)
    result = result.replace(/\+/g, ' ');
    return result
}

const getContentType = response => response.headers.get("content-type");

const downloadFile = async (url, params) => {
    // if (data) {
    // return await fetch(url, {
    //     method: 'POST', headers: {"Content-Type": "application/json; charset=utf-8"},
    //     body: JSON.stringify(data)
    // })
    //     .then(resp => resp.ok ? resp.blob().then(blob => download(blob, getFilename(resp), getContentType(resp))) : handleError(resp)
    // )
    // } else {
    if (params) {
        url += '?' + Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => key + '=' + params[key]).join('&');
    }
    return await fetch(url).then(resp => {
        resp.ok ?
            resp.blob().then(blob => {
                download(blob, getFilename(resp), getContentType(resp))
            }) : handleError(resp)
    })
}

const downloadBlob = async (url) => {
    const _resp = await fetch(url)
    return _resp.ok ? await _resp.blob() : handleError(_resp)
}

const handleError = async resp => {
    const error = await resp.json()
    console.error("HandleError:", error)
    if (error.message) errorAlert(error.message)
    else errorAlert(resp.statusText)
    return false
}

function getParam(name) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    return params[name]
}

function getParamFromHash(name) {
    // Hämta hash-delen av URL:en
    const hash = window.location.hash;

    // Leta efter frågetecknet i hash-delen för att hitta query-parametrarna
    const queryIndex = hash.indexOf('?');

    // Om vi hittar ett frågetecken, extrahera query-delen
    if (queryIndex !== -1) {
        const queryString = hash.substring(queryIndex + 1); // Ta allt efter '?'

        // Använd URLSearchParams för att hantera query-parametrar
        const params = new URLSearchParams(queryString);

        // Returnera parametern med det givna namnet
        return params.get(name);
    }

    // Om det inte finns någon query-string i hash-delen, returnera null
    return null;
}


const NetUtils = {
    doGet,
    doPost,
    doPostWithFiles,
    doDelete,
    exportToExcel,
    downloadFile,
    downloadBlob,
    getParam,
    getParamFromHash
}
export default NetUtils;

