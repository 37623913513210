import {faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from "react";
import Icon from "../../enums/Icon";
import {GridTemplates} from "../../GridTemplates";
import usePreload from "../../hooks/usePreload";
import {i18n} from "../../I18n/I18n";
import {getConvertedImages} from "../../utils/ImageUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import AppendixViewer from "../Appendix/AppendixViewer";
import {CancelButton, MainButton, SaveButton} from "../Buttons/Button";
import {Thumbnail} from "../EditForm/FormItemAppendixGrid";
import Grid from "../Grid/Grid";
import {errorAlert} from "../Overlay/Alert";
import {hideLoading, showLoading} from "../Overlay/Loading";
import './FileUpload.scss'
import renderOverlay from "../Overlay/renderOverlay";
import XlnzDialog from "../XlnzDialog/XlnzDialog";
import XlnzFileUpload from "../XlnzFileUpload/XlnzFileUpload";

const FileUpload = props => {
    const {
        getValues,
        trigger,
        reset,
        save,
        multiple = true,
        accept = 'application/pdf',
        documentSupport,
        getCustomHeaderItems
    } = props

    const fileUploadRef = useRef(null);
    const preload = usePreload()

    const headerTemplate = (options) => {
        const {className, chooseButton} = options;
        return <div className={className}>
            {chooseButton}
            {
                documentSupport && preload.getDocumentList()?.length > 0 &&
                <MainButton faicon={Icon.ADD}
                            label={i18n('select_documents') + '...'}
                            onClick={() => {
                                renderOverlay(<DocumentSelectDialog onSave={_documentList => {
                                    trigger().then(isOk => {
                                        if (isOk) {
                                            showLoading()
                                            const data = getValues()

                                            _documentList.forEach(_document => {
                                                data.appendixList.push({
                                                    document: _document.id,
                                                    name: _document.name,
                                                    fileSize: _document.fileSize
                                                })
                                            })

                                            save(data).then(_model => {
                                                console.log("_MODEL", _model)
                                                reset(_model)
                                                hideLoading()
                                            })
                                            // setUploading(true)
                                        } else {
                                            errorAlert(i18n('required_form_items_missing'))
                                            fileUploadRef.current.clear()
                                        }
                                    })
                                }}/>)
                            }}/>
            }
            {getCustomHeaderItems && getCustomHeaderItems(props, fileUploadRef)}
        </div>
    }

    const emptyTemplate = () => {
        return <div className='emptyTemplate'>
            <FontAwesomeIcon className='dragndropIcon'
                             icon={faImage}/>
            <div className='dragAndDropInfo'>
                {i18n('drag_and_drop_' + (multiple ? 'multiple' : 'single') + '_info')}
            </div>
        </div>
    }

    const uploadHandler = e => {
        const data = getValues()

        const errorFile = e.files.find(file => file.name.length > 128)
        if (errorFile) {
            errorAlert(i18n('error_filename_too_long') + "\n\n\"" + errorFile.name + "\"")
            fileUploadRef.current.clear()
        } else {
            trigger().then(async isOk => {
                if (isOk) {
                    showLoading()

                    const _files = await getConvertedImages(e.files)

                    save(data, _files).then(_model => {
                        // console.log("_MODEL", _model)
                        reset(_model);
                        fileUploadRef.current.clear()
                        hideLoading()
                    })
                    // setUploading(true)
                } else {
                    errorAlert(i18n('required_form_items_missing'))
                    fileUploadRef.current.clear()
                }
            })
        }
    }

    return <div className='FileUpload'>
        <XlnzFileUpload
            ref={fileUploadRef} name="files[]"
            auto={true}
            customUpload={true}
            uploadHandler={e => uploadHandler(e)}

            multiple={multiple}
            accept={accept}
            maxFileSize={40000000}
            headerTemplate={headerTemplate}
            headerClassName='headerTemplate'
            emptyTemplate={emptyTemplate}

            chooseLabel={i18n(multiple ? 'choose_files' : 'choose_file') + '...'}
        />

        {/*<PrimeFileUpload*/}
        {/*    ref={fileUploadRef} name="files[]"*/}
        {/*    auto={true}*/}
        {/*    customUpload={true}*/}
        {/*    uploadHandler={e => uploadHandler(e)}*/}

        {/*    multiple={multiple}*/}
        {/*    accept={accept}*/}
        {/*    maxFileSize={40000000}*/}
        {/*    headerTemplate={headerTemplate}*/}
        {/*    headerClassName='headerTemplate'*/}
        {/*    emptyTemplate={emptyTemplate}*/}

        {/*    chooseLabel={i18n(multiple ? 'choose_files' : 'choose_file') + '...'}*/}
        {/*/>*/}
    </div>
}
export default FileUpload


function DocumentSelectDialog(props) {
    const {
        onSave,
        onHide,
    } = props

    const [selection, setSelection] = useState([])

    const preload = usePreload()
    const [list, setList] = useState(deepCopy(
        preload.getDocumentList()
            .filter(item => item.fileSize > 0)
            .filter(item => item.active)
    ))

    const onSubmit = async () => {
        onSave(selection)
        onHide()
    }

    const fireChange = (list) => {
        setList(list);
    }

    const columns = [
        {
            field: 'thumbnail',
            width: 130,
            body: row => <Thumbnail appendix={row} onClick={e => {
                e.stopPropagation()
                renderOverlay(<AppendixViewer
                    imageList={list}
                    index={list.indexOf(row)}
                    onReload={fireChange}
                />)
            }}/>
        },
        {field: 'description', headerI18n: 'name'},
        {field: 'name', headerI18n: 'filename'},
        {field: 'fileSize', headerI18n: 'size', body: GridTemplates.formatBytes, width: 100, align: 'right'},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    return <XlnzDialog header={i18n('documents')} onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={onSubmit}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div className="EditForm" style={{width: '900px', margin: 0}}>
            <Grid columns={columns}
                  value={list}
                  scrollable={true}
                  hideFooter={true}
                  heightOffset={370}
                  footerColumnGroup={<div/>}

                  selectionMode={'multiple'}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>

    </XlnzDialog>
}