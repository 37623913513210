import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemCustomerAndContact from "../../../components/EditForm/FormItemCustomerAndContact";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemInteger from "../../../components/EditForm/FormItemInteger";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemProject from "../../../components/EditForm/FormItemProject";
import FormItemRowGrid from "../../../components/EditForm/FormItemRowGrid";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {isFreeRow} from "../../../components/Grid/freeRows";
import onRowChange from "../../../components/Grid/onRowChange";
import SelectEditor from "../../../components/Grid/SelectEditor";
import {errorAlert, infoAlert} from "../../../components/Overlay/Alert";
import {confirm, confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Company from "../../../enums/Company";
import Companyprop from "../../../enums/Companyprop";
import CustomerType from "../../../enums/CustomerType";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import TaxReductionType from "../../../enums/TaxReductionType";
import Vat from "../../../enums/Vat";
import VatType from "../../../enums/VatType";
import {GridTemplates} from "../../../GridTemplates";
import useCompanyProp from "../../../hooks/useCompanyProp";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import useQuery from "../../../hooks/useQuery";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {findById, findByMaxId} from "../../../utils/ArrayUtil";
import {addDays, formatDate} from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import useCustomercompanypricelistUtil from "../../Customers/useCustomercompanypricelist";
import ProjectService from "../../Projects/ProjectService";
import ProjectStatus from "../../Projects/ProjectStatus";
import TenderService from "../../Tenders/TenderService";
import InvoiceService from "../InvoiceService";
import InvoiceStatus from "../InvoiceStatus";
import {DownloadInvoiceButton} from "./DownloadInvoiceButton";
import {ExportToFortnoxButton} from "./ExportToFortnoxButton";
import {FetchAgreementrowsButton} from "./FetchAgreementrowsButton";
import {FetchChangeordersButton} from "./fetchChangeorders/FetchChangeordersButton";
import {FetchCostplusWorkButton} from "./fetchCostplusWork/FetchCostplusWorkButton";
import {FetchPaymentplanrowsButton} from "./fetchPaymentplanrows/FetchPaymentplanrowsButton";
import {FetchProjectrentsButton} from "./fetchProjectrents/FetchProjectrentsButton";
import {FetchSummaryButton} from "./fetchSummary/FetchSummaryButton";
import {FetchSupplierinvoicerowsButton} from "./fetchSupplierinvoices/FetchSupplierinvoicerowsButton";
import {FetchTenderoptionsButton} from "./fetchTenderoptions/FetchTenderoptionsButton";
import {FetchTenderrowsButton} from "./fetchTenderrows/FetchTenderrowsButton";
import InvoiceAppendixGrid from "./InvoiceAppendixGrid";
import InvoiceEmailLogDialog from "./InvoiceEmailLogDialog";
import InvoiceHistoryDialog from "./InvoiceHistoryDialog";
import InvoiceHousework from "./InvoiceHousework";
import ReminderGrid from "./ReminderGrid";
import {SendInvoiceButton} from "./SendInvoiceButton";
import SpecialHamntjanstInfo from "./SpecialHamntjanstInfo";
import useInvoiceDefaultValues from "./useInvoiceDefaultValues";

const InvoiceEditForm = props => {
    const context = useContext(AppContext);
    const roles = useRoles()
    const preload = usePreload()
    const pricelistList = preload.getPricelistList()
    const dialog = useDialog()

    const employees = useEmployees()
    const customercompanypricelistUtil = useCustomercompanypricelistUtil()

    const USING_RECO = useCompanyProp(Companyprop.RECO_VENUE_ID, null) !== null
    const isUsingFactoring = useCompanyProp(Companyprop.INVOICE_FACTORING_TEXT)?.length > 0

    const [model, setModel] = useState({
        company: context.currentCompany.id,
        cuser: context.user.id,
        rowList: [],
        appendixList: [],
        sentlogList: []
    });

    const [common, setCommon] = useState();
    const [showInvoiceHistory, setShowInvoiceHistory] = useState(false);
    const [leftToInvoiceMap, setLeftToInvoiceMap] = useState(new Map())


    const disabled = !roles.hasRole(Role.INVOICE_WRITE) ||
        (
            model.id > 0 &&
            model?.status !== InvoiceStatus.DRAFT.id &&
            model?.status !== InvoiceStatus.READY_TO_SEND.id
        )

    const location = useLocation()
    const navigate = useNavigate()
    const {id} = useParams();
    const query = useQuery();

    const defaultValues = useInvoiceDefaultValues()

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, setValue, getValues, watch, control} = form

    const watchRowList = useWatch({control, name: 'rowList'})
    const watchHouseworkList = useWatch({control, name: 'houseworkList'})
    const watchAppendixList = useWatch({control, name: 'appendixList'})
    const watchReminderList = useWatch({control, name: 'reminderList'})

    const watchProject = Number(useWatch({control, name: 'project'}))
    const project = findById(common?.projectList, watchProject)
    const tenderId = project?.tender
    const [tender, setTender] = useState()

    useEffect(() => {
        if (tenderId > 0) {
            console.log("Loading tender...", tenderId)
            TenderService.findById(tenderId).then(setTender)
        }
    }, [tenderId]);


    const watchVatType = useWatch({control, name: 'vatType'})

    const watchCustomer = watch("customer");
    const customer = ArrayUtil.findById(common?.customerList, watchCustomer)


    function getPricelist(_customer, project) {
        if (project) {
            return project.pricelist ? findById(pricelistList, project.pricelist) : undefined
        }
        if (_customer) {
            return findById(pricelistList, customercompanypricelistUtil.getPricelist(_customer.companypricelistList))
        }
    }

    const pricelist = getPricelist(customer, project)

    const watchInvoiceDate = useWatch({control, name: 'invoiceDate'})
    const watchPaymentTerms = watch("paymentTerms");

    const save = async (data, files, force = false) => {
        const saveModel = Object.assign({}, model, data);
        if (saveModel.number?.length === 0) delete saveModel.number

        console.log("SAVE INVOICE", saveModel)
        return InvoiceService.update(saveModel, files).then(_model => {
            if (_model) {
                navigate('/invoices/' + _model.id, {replace: true})
                setModel(_model)
                reset(_model)
                return _model
            }
        });
    }

    function getLeftToInvoice() {
        let result = 0
        for (const value of leftToInvoiceMap.values()) result += value
        return Math.round(result)
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(async _model => {
            if (_model) {
                if (data.project > 0 && getLeftToInvoice() === 0) {
                    const project = await ProjectService.findById(data.project)
                    if (!project.endDate) {
                        confirm({
                            message: i18n('nothing_left_to_invoice_end_project', project.number + ' - ' + project.name),
                            showCancel: false,
                            options: [{
                                label: i18n('yes'), onClick: async () => {
                                    showLoading()
                                    await ProjectService.endProject(data.project)
                                    if (USING_RECO) {
                                        confirm({
                                            message: "Vill du skicka en inbjudan att lämna ett omdöme via Reco?",
                                            options: [
                                                {
                                                    label: i18n('yes'),
                                                    onClick: () => ProjectService.sendRecoInvite(data.project)
                                                }
                                            ]
                                        })
                                    }
                                    hideLoading()
                                }
                            }]
                        })
                    }
                }
                RouterUtils.goBack(navigate)
            }
            hideLoading();
        });
    }

    const onCustomerChange = useCallback(customer => {
        if (customer) {
            setValue('customer', customer.id)
            setValue('vatType', customer ? customer.vatType : VatType.SEVAT)
            setValue('paymentTerms', customer.paymentTerms)
            setValue('dueDate', addDays(watchInvoiceDate, customer.paymentTerms))
        }
    }, [setValue, watchInvoiceDate])

    const onProjectChange = e => {
        const project = ArrayUtil.findById(common.projectList, Number(e.target.value))
        changeProject(project)
    }

    const changeProject = useCallback(project => {
        if (project) {
            showLoading()
            ProjectService.findById(project.id).then(_project => {
                if (project.changeOrder) {
                    errorAlert("Change orders cannot be invoiced.")
                    RouterUtils.goBack(navigate)
                }

                console.log('Project updated: ', _project)
                setValue('project', _project.id)
                setValue('ourReference', _project.projectLeader)

                const customer = ArrayUtil.findById(common.customerList, _project.customer)
                if (!customer) {
                    const _customer = findById(common.customerListAll, _project.customer)
                    if (_customer) {
                        errorAlert(_customer !== undefined
                            ? "Unable to find customer. Is the customer active?"
                            : "Unable to find customer with id '" + _project.customer + "'."
                        )
                    }
                    hideLoading()
                    return
                }
                setValue('customer', customer.id)
                setValue('vatType', customer.vatType)
                setValue('paymentTerms', customer.paymentTerms)
                setValue('dueDate', addDays(watchInvoiceDate, customer.paymentTerms))
                setValue('customercontact', _project.customercontact)
                setValue('markup', _project.markup)
                if (_project.taxReductionType) {
                    const taxReductionTypeObj = TaxReductionType.findById(_project.taxReductionType)
                    setValue('taxReductionType', taxReductionTypeObj.id)
                    setValue('houseworkType', TaxReductionType.getDefaultHouseworkType(taxReductionTypeObj.id)?.id)
                }
                setValue('rowList', [])

                hideLoading()
            })
        }
    }, [setValue, common, watchInvoiceDate, navigate])

    const copyInvoicePdf = data => {
        const sendReq = () => {
            showLoading();
            save(data).then(_model => {
                InvoiceService.copy(_model.id).then(_model => {
                    if (_model) {
                        navigate('/invoices/' + _model.id, {replace: true});
                        setModel(_model);
                        console.log("_model", _model)
                        reset({...defaultValues, ..._model});
                        // setValue('paymentDate', undefined)
                        infoAlert(i18n('copy_is_showing_now'))
                    }
                    hideLoading();
                });
            })
        }
        confirmYes(sendReq)
    }

    const onCreditInvoice = data => {
        const sendReq = () => {
            showLoading();
            save(data).then(_model => {
                InvoiceService.generateCreditNote(_model.id).then(_model => {
                    if (_model) {
                        setModel(_model);
                        reset(_model);
                    }
                    hideLoading();
                });
            })
        }
        confirmYes(sendReq)
    }

    const onAddRows = newRows => {
        const _rowList = [...getValues('rowList')]
        newRows.forEach(r => _rowList.push(r))
        setValue('rowList', _rowList)
    }

    const onReset = _model => {
        setModel(_model);
        reset(_model)
    }

    useMountEffect(async () => {
        showLoading();
        const _customerList = preload.getCustomerList()

        const loadCommons = async (cTime, customerId, projectId) => {
            const [
                _projectList
            ] = await Promise.all([
                ProjectService.findAll({
                    status: ProjectStatus.ONGOING.id,
                    startDate: formatDate(cTime),
                    endDate: formatDate(cTime),
                    forceId: projectId
                })
            ]);

            setCommon({
                projectList: _projectList,
                customerList: _customerList.filter(c => c.active || c.id === customerId),
                customerListAll: _customerList,
                articleList: preload.getArticleList(),
                accountList: preload.getAccountList(),
                costcenterList: preload.getCostcenterList(),
            })
        }

        if (location.state) {
            const _model = Object.assign({}, location.state)

            let _customer
            if (!(Number(_model.customer) > 0)) {
                _customer = findByMaxId(_customerList)
                _model.customer = _customer.id
            }
            await loadCommons(_model.ctime, _model.customer, _model.project);
            setModel(_model)
            reset(_model)
            onCustomerChange(_customer)
        } else {
            if (id > 0) {
                const [
                    _model
                ] = await Promise.all([
                    InvoiceService.findById(id),
                ])

                setModel(_model);
                reset(_model);

                await loadCommons(_model.ctime, _model.customer, _model.project);
            } else {
                const projectId = query.get("project")
                await loadCommons(undefined, undefined, projectId)
                setValue('ourReference', employees.activeUsable.find(item => item.email === context.user.email)?.id)
            }
        }
        hideLoading();
    })

    useEffect(() => {
        if (!common || model.id > 0) return

        const customerId = query.get("customer")
        if (customerId > 0) {
            const customer = findById(common.customerList, customerId)
            onCustomerChange(customer)

            const customercontactId = query.get("customercontact")
            console.log("customercontactId", customercontactId, customer)
            if (customercontactId > 0) {
                const cc = findById(customer.contactList, customercontactId)
                setValue('customercontact', cc?.id)
            }
        }

        const invoiceDate = query.get("invoiceDate")
        if (invoiceDate) {
            setValue('invoiceDate', invoiceDate)
            setValue('dueDate', addDays(invoiceDate, watchPaymentTerms))
        }

        const projectId = query.get("project")
        if (projectId > 0) {
            const project = ArrayUtil.findById(common.projectList, projectId)
            changeProject(project)
        }

        // Make sure we only do this once by clearing the values.
        navigate('/invoices/0', {replace: true})
    }, [model, navigate, setValue, watchPaymentTerms, common, query, changeProject, onCustomerChange])


    if (!common) return <Spinner/>

    const getCustomerEmail = () => {
        const customerId = Number(watchCustomer);
        return common.customerList.find(c => c.id === customerId)?.invoiceEmail;
    }

    const menuItems = [
        {
            label: i18n('invoicing_history') + '...',
            faicon: Icon.LOG,
            command: handleSubmit(data => {
                showLoading();
                save(data).then(_model => {
                    setShowInvoiceHistory(true)
                    hideLoading()
                })
            }),
            hide: !model.id || !model.project
        },
        {
            label: i18n('sent_log') + '...',
            faicon: Icon.LOG,
            command: () => renderOverlay(<InvoiceEmailLogDialog tender={model}/>),
            hide: !model.id
        },
        {
            label: i18n('do_copy') + '...',
            faicon: Icon.COPY,
            command: handleSubmit(copyInvoicePdf),
            hide: !model.id
        },
        {
            label: i18n('do_credit') + '...',
            faicon: Icon.CREDIT,
            command: handleSubmit(onCreditInvoice),
            hide: model.creditNote || !model.number
        },
        {
            label: i18n('unlock') + '...',
            faicon: Icon.UNLOCK,
            command: () => {
                model.status = InvoiceStatus.DRAFT.id
                save(model, undefined, true)
            },
            hide: !disabled || !roles.hasRole(Role.INVOICE_UNLOCK)
        },

    ].filter(item => !item.hide)

    function isCellEditable(field, rowData) {
        if (field === 'aprice' && rowData?.timeregList?.length > 0) {
            return true
        }

        switch (field) {
            case 'article':
            case 'unit':
            case 'quantity':
            case 'aprice':
            case 'price':
                return !(
                    rowData?.paymentplanrowList?.length > 0
                    || rowData?.tenderrowList?.length > 0
                    || rowData?.tenderoptionList?.length > 0
                    || rowData?.timeregList?.length > 0
                    || rowData?.timeregarticleList?.length > 0
                    || rowData?.projectrentList?.length > 0
                )
            case 'discount':
                return !(
                    rowData?.tenderrowList?.length > 0
                    || rowData?.tenderoptionList?.length > 0
                    || rowData?.timeregarticleList?.length > 0
                    || rowData?.projectrentList?.length > 0
                )
            default:
                return true
        }
    }

    function isHousework() {
        return customer?.type === CustomerType.PRIVATE_PERSON.id
    }

    function onLeftToInvoiceReport(name, value) {
        leftToInvoiceMap.set(name, value)
        setLeftToInvoiceMap(leftToInvoiceMap)
    }

    function getCustomRowColumns(rowList, fireChange) {
        if (watchVatType !== VatType.SEVAT.id) return []
        return [{
            afterField: 'price',
            field: 'vat',
            header: i18n('vat'),
            body: (rowData, column) => {
                return !isFreeRow(column.props.value, rowData) && rowData.price !== 0
                    ? GridTemplates.percentTimes100(rowData, column)
                    : ''
            },
            excelBody: (row, column) => row[column.field],
            editor: disabled ? undefined : e => <SelectEditor
                hideNoneOption={true}
                event={e}
                options={Vat.findByCountry(context.currentCompany.address.country)}
                optionValue='id'
                onChange={e2 => e.editorCallback(e2?.value)}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 50
        }]
    }

    return (
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n('customer_invoice')} {model.number}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="button-row">
                    <div className='left-buttons'>
                        <SaveButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting}/>
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        {!disabled && <ExportToFortnoxButton handleSubmit={handleSubmit} save={save} reset={onReset}/>}
                        <DownloadInvoiceButton handleSubmit={handleSubmit} save={save}/>
                        <SendInvoiceButton dialog={dialog} handleSubmit={handleSubmit} save={save} reset={onReset}
                                           form={form}/>
                        {menuItems.length > 0 && <ExtraMenu itemList={menuItems}/>}
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemProject options={common.projectList} onChange={onProjectChange} {...form}/>
                        <FormItemEmployee name='ourReference' labelI18n='our_reference' required {...form}/>
                        <FormItemDate name='invoiceDate' labelI18n='invoice_date'
                                      onChange={e => setValue('dueDate', addDays(e, watchPaymentTerms))}
                                      required {...form} />
                        <FormItemDate name='dueDate' labelI18n='due_date' {...form} />
                    </div>
                    <div className='column'>
                        <FormItemCustomerAndContact
                            onCustomerEdit={() => {
                                const data = getValues()
                                navigate(location.pathname, {replace: true, state: data});
                                navigate('/customers/' + (data.customer ? data.customer : 0));
                            }}
                            onCustomerChange={onCustomerChange}
                            form={form}/>
                        <FormItemTextArea name='markup' labelI18n='reference_no' maxLength={250} {...form}/>
                        <FormItemInteger name='paymentTerms' labelI18n='payment_terms'
                                         max={365}
                                         onKeyUp={e => {
                                             // console.log("epaymentTerms", watchInvoiceDate, e.target.value, addDays(watchInvoiceDate, e.target.value))
                                             setValue('dueDate', addDays(watchInvoiceDate, e.target.value))
                                         }
                                         }
                                         required {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemDropdown name='vatType' labelI18n='vat_type' options={VatType.values()}
                                          required {...form} />
                        {
                            isUsingFactoring &&
                            <FormItemCheckbox name='factoring' labelI18n='pledged' {...form}
                                              disabled={disabled || customer?.disableFactoring}
                            />
                        }
                        <FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>
                        <FormItemOptionalDate name='paymentDate' labelI18n='payment_date' {...form}
                                              disabled={!roles.hasRole(Role.INVOICE_UNLOCK)}/>
                        <FormItemDropdown name='status' labelI18n='status'
                                          options={InvoiceStatus.values().filter(status => status.userDefined)}
                                          width={150}
                                          required {...form} />
                    </div>
                </div>

                <TabView renderActiveOnly={false}>
                    <TabPanel key='rows' header={i18n('rows') + ' (' + (watchRowList.length) + ')'}>
                        <FormItemRowGrid
                            name='rowList'
                            articleList={common.articleList}
                            pricelist={pricelist}
                            accountList={common.accountList}
                            costcenterList={common.costcenterList}
                            defaultRowValue={({
                                id: 0,
                                article: undefined,
                                name: '',
                                quantity: 0,
                                aprice: 0,
                                discount: 0,
                                price: 0,
                                vat: Vat.VAT25.id,
                                costcenter: project?.costcenter
                            })}
                            isCellEditable={isCellEditable}
                            vatType={watchVatType}
                            housework={isHousework()}
                            houseworkList={watchHouseworkList}
                            getCustomColumns={getCustomRowColumns}
                            leftFooter={
                                <>
                                    {!disabled &&
                                        <div className="button-row">
                                            <FetchPaymentplanrowsButton
                                                project={project} rowList={watchRowList}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('paymentplanrows', value)}/>
                                            <FetchTenderrowsButton
                                                project={project} rowList={watchRowList}
                                                tender={tender}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('tenderrows', value)}/>
                                            <FetchTenderoptionsButton
                                                project={project} rowList={watchRowList}
                                                tender={tender}
                                                onAddRows={onAddRows}/>
                                            <FetchProjectrentsButton
                                                project={project} rowList={watchRowList}
                                                accountList={common.accountList}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('tenderrents', value)}/>
                                            <FetchCostplusWorkButton
                                                project={project} rowList={watchRowList} vatType={watchVatType}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('debitHours', value)}/>
                                            <FetchSupplierinvoicerowsButton
                                                project={project} rowList={watchRowList} vatType={watchVatType}
                                                accountList={common.accountList}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('supplierinvoicerows', value)}/>
                                            <FetchChangeordersButton
                                                project={project} rowList={watchRowList} vatType={watchVatType}
                                                invoiceDate={watchInvoiceDate}
                                                onAddRows={onAddRows}
                                                onLeftToInvoiceReport={value => onLeftToInvoiceReport('changeorders', value)}/>

                                            {
                                                roles.hasRole(Role.SUPERADMIN) && context.currentCompany.id === Company.XLNZ_TECH &&
                                                <FetchAgreementrowsButton invoiceDate={watchInvoiceDate}
                                                                          customer={customer}
                                                                          rowList={watchRowList}
                                                                          accountList={common.accountList}
                                                                          onAddRows={onAddRows}/>
                                            }

                                            <FetchSummaryButton rowList={watchRowList} onAddRows={onAddRows}/>
                                        </div>
                                    }
                                </>
                            }
                            {...form}/>
                    </TabPanel>

                    {
                        isHousework() &&
                        <TabPanel key='house_work' header={i18n('house_work')}>
                            <InvoiceHousework
                                disabled={disabled}
                                form={form}
                                customer={customer}
                                vatType={watchVatType}
                                rowList={watchRowList}
                            />
                        </TabPanel>
                    }

                    {
                        model.id > 0 &&
                        <TabPanel key='reminders' header={i18n('reminders') + ' (' + (watchReminderList.length) + ')'}>
                            <Controller name='reminderList'
                                        control={control}
                                        render={({field: {ref, value, onChange}}) =>
                                            <ReminderGrid
                                                inputRef={ref} value={value} onChange={onChange}
                                                invoiceModel={model}
                                                save={save}
                                                getCustomerEmail={getCustomerEmail}
                                                dialog={dialog}
                                            />}/>
                        </TabPanel>
                    }
                    <TabPanel key='appendices' header={i18n('appendices') + ' (' + (watchAppendixList.length) + ')'}>
                        <InvoiceAppendixGrid form={form} save={save}/>
                    </TabPanel>

                </TabView>
            </form>

            {
                showInvoiceHistory &&
                <InvoiceHistoryDialog projectId={model.project} visible={true}
                                      onHide={() => setShowInvoiceHistory(false)}/>
            }

            {context.currentCompany.id === 18 && <SpecialHamntjanstInfo form={form}/>}
            {dialog.render()}
        </div>
    );
}

export default InvoiceEditForm;
